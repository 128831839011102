import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ReportProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MERLIN_API)
  }

  getReportsPerDate (dateRange, channel = ['web', 'pos', 'manual', 'click_and_collect']) {
    const paramChannel = channel.length === 1 ? channel[0] : 'all'
    this.setHeader(getAuthToken())
    return this.get(`/stats-reports?startDate=${dateRange[0]}&endDate=${dateRange[1]}&channel=${paramChannel}`)
  }

  getCrmReportsStats (dateRange, channel = ['web', 'pos', 'manual']) {
    const paramChannel = channel.length === 1 ? channel[0] : 'all'
    this.setHeader(getAuthToken())
    return this.get(`/stats-reports/crm-reports/stats?startDate=${dateRange[0]}&endDate=${dateRange[1]}&channel=${paramChannel}`)
  }

  getCrmReportsAmountMemberAndOrder (dateRange, channel = ['web', 'pos', 'manual']) {
    const paramChannel = channel.length === 1 ? channel[0] : 'all'
    this.setHeader(getAuthToken())
    return this.get(`/stats-reports/crm-reports/amount-member-and-order?startDate=${dateRange[0]}&endDate=${dateRange[1]}&channel=${paramChannel}`)
  }

  getCrmReportsMemberStatusPerMonth (channel = ['web', 'pos', 'manual']) {
    const paramChannel = channel.length === 1 ? channel[0] : 'all'
    this.setHeader(getAuthToken())
    return this.get(`/stats-reports/crm-reports/member-status-per-month?channel=${paramChannel}`)
  }

  getCrmReportsMemberStatusPerDate (dateRange, channel = ['web', 'pos', 'manual']) {
    const paramChannel = channel.length === 1 ? channel[0] : 'all'
    this.setHeader(getAuthToken())
    return this.get(`/stats-reports/crm-reports/member-status-per-date?startDate=${dateRange[0]}&endDate=${dateRange[1]}&channel=${paramChannel}`)
  }

  getGaReport (payload) {
    this.setHeader(getAuthToken())
    return this.get('/ga', payload)
  }
}

export default ReportProvider
