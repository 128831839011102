<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row
      justify="space-between"
      class="head-search ma-0">
      <v-spacer />
      <v-col class="d-flex">
        <v-spacer />
        <v-checkbox
          v-model="allSelected"
          class="mx-1"
          label="All" />
        <v-checkbox
          v-for="(channel, index) in channels"
          :key="index"
          v-model="channelSelected"
          class="mx-1"
          :value="channel.value"
          :label="channel.label" />
      </v-col>
      <v-col
        cols="auto"
        class="d-flex align-center date-picker-col">
        <date-range-picker v-model="dateRange" />
      </v-col>
    </v-row>
    <v-row
      class="products-row d-flex justify-center">
      <v-col cols="12">
        <h1 class="page-title">
          CRM Dashbroad
        </h1>
      </v-col>
      <v-col
        cols="6"
        class="number-col">
        <v-card
          class="number-box total"
          elevation="2">
          <h3 class="title">
            Total Members
          </h3>
          <h2
            v-if="ready.stats"
            class="content">
            {{ showFullPriceFormat(stats.totalMembers, 0) }}
          </h2>
          <h2
            v-else
            class="content">
            loading...
          </h2>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        class="number-col">
        <v-card
          class="number-box total"
          elevation="2">
          <h3 class="title">
            Average Spending
          </h3>
          <h2
            v-if="ready.stats"
            class="content">
            {{ showFullPriceFormat(stats.averageSpending, 0) }} baht
          </h2>
          <h2
            v-else
            class="content">
            loading...
          </h2>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        class="number-col">
        <v-card
          class="number-box total"
          elevation="2">
          <h3 class="title">
            New Members
          </h3>
          <h2
            v-if="ready.stats"
            class="content">
            {{ showFullPriceFormat(stats.newMembers, 0) }}
          </h2>
          <h2
            v-else
            class="content">
            loading...
          </h2>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        class="number-col">
        <v-card
          class="number-box total"
          elevation="2">
          <h3 class="title">
            Returning Member
          </h3>
          <h2
            v-if="ready.stats"
            class="content">
            {{ showFullPriceFormat(stats.returnMembers, 0) }}
          </h2>
          <h2
            v-else
            class="content">
            loading...
          </h2>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-center">
        <line-chart
          v-if="ready.memberStatusPerDate"
          :chart-data="charts.newAndReturn.dataCollection"
          :options="charts.newAndReturn.chartOptions"
          class="chart-graph" />

        <div
          v-else
          class="d-flex justify-center align-center py-16">
          <h3>จำนวนลูกค้าใหม่ x จำนวนลูกค้าที่กลับมาซื้ออีกครั้ง</h3>
          <v-progress-circular
            class="ml-1"
            indeterminate
            color="black" />
        </div>
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-center">
        <bar-chart
          v-if="ready.amountMemberAndOrder"
          :chart-data="orderNumber.dataCollection"
          :options="orderNumber.chartOptions"
          class="chart-graph" />

        <div
          v-else
          class="d-flex justify-center align-center py-16">
          <h3>จำนวนลูกค้าที่กลับมาซื้อซ้ำ</h3>
          <v-progress-circular
            class="ml-1"
            indeterminate
            color="black" />
        </div>
      </v-col>

      <v-col
        cols="12"
        class="d-flex justify-center">
        <line-chart
          v-if="ready.memberStatusPerMonth"
          :chart-data="charts.members.dataCollection"
          :options="charts.members.chartOptions"
          class="chart-graph" />

        <div
          v-else
          class="d-flex justify-center align-center py-16">
          <h3>ลูกค้าใหม่ x ลูกค้าปัจุบัน x ลูกที่หายไป</h3>
          <!-- <h1>LOADING </h1> -->
          <v-progress-circular
            class="ml-1"
            indeterminate
            color="black" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReportProvider from '@/resources/ReportProvider'
import { mapActions } from 'vuex'
import Colors from '@/assets/json/BranchColors.json'
import DateRangePicker from '../components/DatesPicker.vue'
import LineChart from '../components/charts/LineChart'
import BarChart from '../components/charts/BarChart'

const ReportService = new ReportProvider()

export default {
  components: {
    DateRangePicker,
    LineChart,
    BarChart
  },
  data () {
    return {
      stats: {
        averageSpending: 2225.221159614064,
        newMembers: 0,
        returnMembers: 0,
        totalMembers: 27673
      },
      ready: {
        stats: false,
        memberStatusPerMonth: false,
        memberStatusPerDate: false,
        amountMemberAndOrder: false
      },
      dateRange: [],
      allSelected: false,
      channelSelected: [],
      channels: [
        {
          label: 'POS',
          value: 'pos'
        },
        {
          label: 'Online',
          value: 'web'
        }
      ],
      orderNumber: {
        dataCollection: null,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'จำนวนลูกค้าที่กลับมาซื้อซ้ำ',
            position: 'top'
          }
        }
      },
      charts: {
        newAndReturn: {
          dataCollection: {
            labels: [],
            datasets: []
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'จำนวนลูกค้าใหม่ x จำนวนลูกค้าที่กลับมาซื้ออีกครั้ง',
              position: 'top'
            }
          }
        },
        members: {
          dataCollection: {
            labels: [],
            datasets: []
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: 'ลูกค้าใหม่ x ลูกค้าปัจุบัน x ลูกที่หายไป',
              position: 'top'
            }
          }
        }
      },
      watching: null
    }
  },
  watch: {
    allSelected (val) {
      const temp = this.channelSelected.length !== 2 ? this.channelSelected : []
      this.channelSelected = val ? ['pos', 'web'] : temp
    },
    channelSelected (val) {
      this.allSelected = val.length === this.channels.length
      this.watchingChannels()
    },
    dateRange: {
      handler () {
        this.fillData()
        this.initLabel()
        this.getStats()
      },
      deep: true
    }
  },
  mounted () {
    this.initDefaultDate()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    watchingChannels () {
      clearTimeout(this.watching)
      this.watching = setTimeout(() => {
        this.fillData()
        this.initLabel()
        this.getStats()
      }, 1000)
    },
    setAllToChannelSelect () {
      this.channelSelected = ['pos', 'web']
    },
    async getStats () {
      try {
        this.initStats()
        this.initOrderNumber()
        this.initMembersPerMonth()
        this.initMembersPerDate()
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
        console.error('GET CRM REPORTS ERROR')
      } finally {
        this.loaded = true
      }
    },
    async initStats () {
      this.ready.stats = false
      const { data: stats } = await ReportService.getCrmReportsStats(this.dateRange, this.channelSelected)
      this.stats = stats
      this.ready.stats = true
    },
    async initOrderNumber () {
      this.ready.amountMemberAndOrder = false
      const { data: reports } = await ReportService.getCrmReportsAmountMemberAndOrder(this.dateRange, this.channelSelected)

      this.orderNumber.dataCollection = {
        labels: [],
        datasets: []
      }
      this.orderNumber.dataCollection.labels = reports.map((report) => report.orderAmount)
      this.orderNumber.dataCollection.datasets = [{
        label: 'จำนวนคน',
        data: reports.map((each) => each.memberAmount),
        backgroundColor: 'blue',
        borderColor: 'blue'
      }]

      this.ready.amountMemberAndOrder = true
    },
    async initMembersPerMonth () {
      this.ready.memberStatusPerMonth = false
      const { data: reportsPerMonth } = await ReportService.getCrmReportsMemberStatusPerMonth(this.channelSelected)

      this.charts.members.dataCollection.labels = reportsPerMonth.map((report) => report.label)
      this.charts.members.dataCollection.datasets = this.prepareDataForDataSets(
        reportsPerMonth,
        ['lostMembers', 'activeMembers', 'newMembers']
      )
      this.ready.memberStatusPerMonth = true
    },
    async initMembersPerDate () {
      this.ready.memberStatusPerDate = false
      const { data: reportsPerDate } = await ReportService.getCrmReportsMemberStatusPerDate(this.dateRange, this.channelSelected)

      this.charts.newAndReturn.dataCollection.labels = reportsPerDate.map((report) => report.label)
      this.charts.newAndReturn.dataCollection.datasets = this.prepareDataForDataSets(
        reportsPerDate,
        ['returnMembers', 'newMembers']
      )
      this.ready.memberStatusPerDate = true
    },
    prepareDataForDataSets (reports, charts) {
      const result = []
      let counter = 0
      charts.forEach((chart) => {
        result.push({
          label: chart.toUpperCase(),
          fill: false,
          borderColor: Colors[counter].color,
          backgroundColor: Colors[counter].color,
          data: reports.map((report) => report[chart])
        })
        counter = (counter + 2) % 3
      })

      return result
    },
    fillData () {
      Object.keys(this.charts).forEach((chart) => {
        this.charts[chart].dataCollection = {
          labels: [],
          datasets: []
        }
      })

      this.orderNumber.dataCollection = {
        labels: [],
        datasets: []
      }
    },
    initLabel () {
      const startDate = this.$dayjs(this.dateRange[0])
      const endDate = this.$dayjs(this.dateRange[1])
      const label = []

      for (let i = 0; i <= endDate.diff(startDate, 'day'); i++) {
        label[i] = startDate.add(i, 'day').format('YYYY-MM-DD')
      }

      this.charts.newAndReturn.dataCollection.labels = label
    },
    initDefaultDate () {
      const startDate = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      const endDate = this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD')
      this.dateRange = [startDate, endDate]
    },
    showFullPriceFormat (number = 0, fixed = 2) {
      return parseFloat(number).toFixed(fixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>

<style scoped>

.page-title {
  text-decoration: underline;
}

.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 70%;
  border: 2px solid black;
  position: relative;
}

.number-box .title {
  position: absolute;
  padding: 0 5px;
  top: -17px;
  left: 5px;
  background-color: #fff;
}

.number-box .extend {
  position: absolute;
  bottom: 0px;
  right: 2px;
}

.number-row {
  margin: 20px auto;
}

.number-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-title {
  text-decoration: underline;
}

.chart-graph {
  width: 90%;
}
</style>
